import React from 'react'
import PropTypes from 'prop-types'
import { StaticImage } from 'gatsby-plugin-image'

// Current card implementaiton:
//  - Cards are organized using CSS grid, which sets a fixed column width in rem units,
//    and reflows the columns using `auto-fill` property passed into the `repeat` function
//    (while maintaing a grid structure, unlike flexbox).
//  - Cards are fixed width (which is locked by css grid), and the text section of the card has a min height, which
//    ensures that when cards are wrapped to next line, the cards all maintain same height
//  - The image is below the text, and it takes the full width of the card (while maintaining aspect ratio). Therefore as long
//    as the card images (transparent PNGs) are all the same dimensions and start at the same distance away from the top of the card
//    (hence the min width on the text section), the cards will all have the same height
//  - Since the aspect ratio of the image is fixed, the horizontal line is
//    positioned absolutely, relative to the image (so it stays in the same position regardless of image being resized)

// Note: If the yellow color is going to be used anywhere else, create a Bootstrap modifier class for it

function ProductOverviewCard(props) {
  return (
    <div className="border border-dark position-relative d-flex flex-column h-100">
      {/* Need to have a min height here in rem so that when cards wrap, they are all still same height.
        The value should be manually adjusted to fit the largest amount of text between the cards. */}
      <div
        className="pt-typist48 px-typist32 pb-typist16"
        style={{ minHeight: '20rem' }}
      >
        <h3 className="fs-3 fw-bold mb-typist16">{props.title}</h3>
        <p className="lh-typist32 mb-0">{props.textBody}</p>
      </div>
      <div className="mt-auto position-relative">
        {/* This is the table line, absolute positioned as a percentage of the illustration's height */}
        <div
          style={{ left: '1.2rem' }}
          className="mt-auto position-absolute bottom-0 w-100"
        >
          {props.imageUnderTable}
        </div>
        <div
          style={{
            minHeight: '40%',
          }}
          className="mt-auto border-top border-dark position-absolute bottom-0 w-100 bg-typist-light"
        ></div>
        <div className="position-relative" style={{ left: '1.2rem' }}>
          {props.children}
        </div>
      </div>
    </div>
  )
}

ProductOverviewCard.propTypes = {
  title: PropTypes.string.isRequired,
  textBody: PropTypes.string.isRequired,
  imageUnderTable: PropTypes.element,
}

const WhyChooseTypist = () => {
  return (
    <div className="container my-typist56 my-md-typist96 my-xl-typist240">
      <section aria-labelledby="whyChooseTypist">
        <h2
          className="fs-1 text-center mb-typist40 mb-xl-typist64 fw-bold"
          id="whyChooseTypist"
        >
          Why Choose Typist
        </h2>
        <div className="typist-product-overview-cards-container">
          <ProductOverviewCard
            title={'Easy to Use'}
            textBody={
              "Setup your class in seconds. Track your students' results, practice time, and course progress with a single click. Typist is simple and intuitive."
            }
          >
            <StaticImage
              src="../images/easy_to_use@2x.png"
              // Empty alt because this image is for presentational purposes only
              alt=""
              width={420}
              placeholder="none"
            ></StaticImage>
          </ProductOverviewCard>

          <ProductOverviewCard
            title={'Learn Anywhere'}
            textBody={
              "Use Mac or Windows, any browser and any device. There's nothing to install, and students can work anywhere they have a wifi connection."
            }
            imageUnderTable={
              <StaticImage
                src="../images/learn_anywhere_layer_below@2x.png"
                // Empty alt because this image is for presentational purposes only
                alt=""
                placeholder="none"
                width={420}
              ></StaticImage>
            }
          >
            <StaticImage
              src="../images/learn_anywhere_layer_above@2x.png"
              // Empty alt because this image is for presentational purposes only
              alt=""
              placeholder="none"
              width={420}
            ></StaticImage>
          </ProductOverviewCard>

          <ProductOverviewCard
            title={'Smart Software'}
            textBody={
              'Leveraging the power of artificial intelligence, Typist provides immediate feedback, automated grading, strength/weakness analysis, and individualized learning plans for students.'
            }
          >
            <StaticImage
              src="../images/smart_software@2x.png"
              // Empty alt because this image is for presentational purposes only
              alt=""
              placeholder="none"
              width={420}
            ></StaticImage>
          </ProductOverviewCard>
        </div>
      </section>
    </div>
  )
}

export default WhyChooseTypist
