import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'
import TestimonialsCarousel from '../components/testimonialsCarousel'
import WhyChooseTypist from '../components/whyChooseTypist'
import OurProducts from '../components/ourProducts'
import LetUsHelp from '../components/letUsHelp'
import StatsSection from '../components/statsSection'
import BikeHero from '../components/bikeHero'
import BaristaHero from '../components/baristaHero'
import DeskHero from '../components/deskHero'

// This has same implementation as bootstrap's page
// Make sure this element is inside and as close to the opening of the <body> element as possible
const SkipToMainContent = () => (
  <div className="visually-hidden-focusable">
    <div className="container">
      <a className="d-inline-flex p-2 m-1" href="#content">
        Skip to main content
      </a>
    </div>
  </div>
)

const IndexPage = ({ data }) => (
  <>
    <SkipToMainContent></SkipToMainContent>
    <Layout>
      <SEO title="Join" />
      <Hero></Hero>
      <StatsSection></StatsSection>
      <WhyChooseTypist></WhyChooseTypist>
      <BikeHero></BikeHero>
      <OurProducts></OurProducts>
      <TestimonialsCarousel></TestimonialsCarousel>
      <BaristaHero></BaristaHero>
      <LetUsHelp></LetUsHelp>
      <DeskHero></DeskHero>
    </Layout>
  </>
)

export default IndexPage
