import React, { useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import classnames from 'classnames'

// Note: Want to eager load these images because when accordion is switching between them, want it to be pre-loaded

const ImageTestingMadeEasy = () => (
  <StaticImage
    src="../images/keyboarding_testing_made_easy@2x.png"
    loading="eager"
    width={960}
    quality={100}
    // Empty alt because this image is for presentational purposes only
    alt=""
  ></StaticImage>
)

const ImageHomerowTech = () => (
  <StaticImage
    src="../images/keyboarding_homerow_tech@2x.png"
    loading="eager"
    width={960}
    quality={100}
    // Empty alt because this image is for presentational purposes only
    alt=""
  ></StaticImage>
)

const ImageIntuitiveGradesheet = () => (
  <StaticImage
    src="../images/keyboarding_intuitive_gradesheet@2x.png"
    loading="eager"
    width={960}
    quality={100}
    // Empty alt because this image is for presentational purposes only
    alt=""
  ></StaticImage>
)

const ImageAdvancedAnalytics = () => (
  <StaticImage
    src="../images/keyboarding_advanced_analytics@2x.png"
    width={960}
    quality={100}
    // Empty alt because this image is for presentational purposes only
    alt=""
  ></StaticImage>
)
const ImageLimitlessContent = () => (
  <StaticImage
    src="../images/keyboarding_limitless_content@2x.png"
    loading="eager"
    width={960}
    quality={100}
    // Empty alt because this image is for presentational purposes only
    alt=""
  ></StaticImage>
)

const ImageFormativeAssessments = () => (
  <StaticImage
    src="../images/documents_formative_assessments@2x.png"
    loading="eager"
    width={960}
    quality={100}
    // Empty alt because this image is for presentational purposes only
    alt=""
  ></StaticImage>
)

const ImageAutomatedFeedback = () => (
  <StaticImage
    src="../images/documents_automated_feedback@2x.png"
    loading="eager"
    width={960}
    quality={100}
    // Empty alt because this image is for presentational purposes only
    alt=""
  ></StaticImage>
)

const ImageWorkplaceExperience = () => (
  <StaticImage
    src="../images/documents_workplace_experience@2x.png"
    loading="eager"
    width={960}
    quality={100}
    // Empty alt because this image is for presentational purposes only
    alt=""
  ></StaticImage>
)

const ImageStyleGuide = () => (
  <StaticImage
    src="../images/documents_style_guide@2x.png"
    loading="eager"
    width={960}
    quality={100}
    // Empty alt because this image is for presentational purposes only
    alt=""
  ></StaticImage>
)

const Image75Documents = () => (
  <StaticImage
    src="../images/documents_75_documents@2x.png"
    loading="eager"
    width={960}
    quality={100}
    // Empty alt because this image is for presentational purposes only
    alt=""
  ></StaticImage>
)

const ImageCarousel = props => {
  return (
    <div className="carousel slide">
      <div className="carousel-inner">
        {props.items.map(({ image }, idx) => {
          return (
            <div
              key={`carousel-item-${idx}`}
              className={classnames('carousel-item', {
                active: props.idx === idx,
              })}
            >
              {image}
            </div>
          )
        })}
      </div>
    </div>
  )
}

ImageCarousel.propTypes = {
  idx: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired,
}

const AccordionItem = props => {
  return (
    <div className="accordion-item px-0">
      <h4
        className="accordion-header"
        id={`heading-${props.parentShortTitle}-${props.idx}`}
      >
        <button
          className={classnames('accordion-button fs-4 fw-bold px-0', {
            collapsed: props.idx !== 0,
          })}
          type="button"
          onClick={props.onClick}
          aria-expanded="true"
          aria-controls={`collapse-${props.parentShortTitle}-${props.idx}`}
          data-bs-target={`#collapse-${props.parentShortTitle}-${props.idx}`}
          data-bs-toggle="collapse"
        >
          {props.title}
        </button>
      </h4>
      <div
        id={`collapse-${props.parentShortTitle}-${props.idx}`}
        className={classnames('accordion-collapse collapse', {
          show: props.idx === 0,
        })}
        aria-labelledby={`heading-${props.parentShortTitle}-${props.idx}`}
        data-bs-parent={`#accordion-${props.parentShortTitle}`}
      >
        <div className="accordion-body px-0">
          <div className="lh-typist32">{props.bodyText}</div>
          {/* Only display image when in mobile view */}
          <div className="d-block d-xl-none my-typist40">{props.image}</div>
        </div>
      </div>
    </div>
  )
}

AccordionItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  idx: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  bodyText: PropTypes.string.isRequired,
  image: PropTypes.element,
  // A single word that is used for generating unique ids. eg. 'keyboarding' or 'documents'
  parentShortTitle: PropTypes.string.isRequired,
}

// A component representing a single product - either Keyboarding or Document Production
const Product = props => {
  // Switching this variable based on what item is clicked in the accordion,
  // communicates which image to show in the carousel
  const [activeItem, setActiveItem] = useState(0)

  return (
    <div>
      <div className="row justify-content-center mb-typist48">
        <div className="col-xl-8">
          <h3 className="fs-2 fw-bold mb-typist24 mb-md-typist16 text-center">
            {props.title}
          </h3>
          <p className="fs-4 lh-typist40">{props.description}</p>
        </div>
      </div>

      <div className="row">
        <div
          className={classnames('col-12', 'col-xl-5', {
            'order-lg-last': props.reverseOrder,
          })}
        >
          {/* <h3 className="fs-2 fw-bold mb-typist24 mb-md-typist16">
          {props.title}
        </h3>
        <p className="mb-typist40 fs-4 lh-typist40">{props.description}</p> */}
          <div
            className="accordion accordion-typist"
            id={`accordion-${props.shortTitle}`}
          >
            {props.items.map((item, idx) => {
              return (
                <AccordionItem
                  key={`accordion-item-${idx}`}
                  onClick={() => {
                    setActiveItem(idx)
                  }}
                  idx={idx}
                  parentShortTitle={props.shortTitle}
                  image={item.image}
                  title={item.title}
                  bodyText={item.bodyText}
                  open={idx === 0}
                ></AccordionItem>
              )
            })}
          </div>
        </div>
        <div
          className={classnames('d-none', 'd-xl-block', 'col-lg-7', {
            'order-lg-first': props.reverseOrder,
          })}
        >
          <div className="px-lg-5">
            <ImageCarousel idx={activeItem} items={props.items}></ImageCarousel>
          </div>
        </div>
      </div>
    </div>
  )
}

Product.propTypes = {
  // reverses the display order of accordion and image in desktop view
  reverseOrder: PropTypes.bool,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  // A single word that is used for generating unique ids. eg. 'keyboarding' or 'documents'
  shortTitle: PropTypes.string.isRequired,
}

const OurProducts = () => {
  return (
    <section
      id="products"
      className="container my-typist56 my-md-typist96 my-xl-typist240"
    >
      <h2 className="fs-1 text-center mb-typist32 mb-md-typist64 fw-bold">
        Our Products
      </h2>
      <div className="mb-typist32 mb-md-typist96 mb-xl-typist240">
        <Product
          title="Typist Keyboarding"
          shortTitle="keyboarding"
          description="The most innovative typing trainer ever created, Typist Keyboarding contains over 100+ lessons, 1000+ timings, and advanced capabilities designed specifically to prepare college students for career success."
          items={[
            {
              title: 'Limitless Content',
              bodyText:
                '1000+ Timed Writings provide a virtually unlimited supply of timed writings for your tests, all hand curated to college-level English.',
              image: <ImageLimitlessContent></ImageLimitlessContent>,
            },
            {
              title: 'Home Row Technology',
              bodyText:
                "Placing fingers in the Home Row Position is the first step to keyboarding success. Typist's Home Row Technology verifies student finger position before they start any typing activity.",
              image: <ImageHomerowTech></ImageHomerowTech>,
            },
            {
              title: 'An Intuitive Gradesheet',
              bodyText:
                'Typist gives you all the data you need to manage your class: lesson progress, test results, and daily practice time down to the second in a beautifully designed dashboard.',
              image: <ImageIntuitiveGradesheet></ImageIntuitiveGradesheet>,
            },
            {
              title: 'Advanced Analytics',
              bodyText:
                'Typist Keyboarding continously analyzes typing habits and applies advanced algorithmic techniques to determine individual strengths and weaknesses.',
              image: <ImageAdvancedAnalytics></ImageAdvancedAnalytics>,
            },
            {
              title: 'Testing Made Easy',
              bodyText:
                'With two clicks, give a timed writing test to your entire class. Students instantly receive a notification in their browser, and the results come straight back to your dashboard.',
              image: <ImageTestingMadeEasy></ImageTestingMadeEasy>,
            },
          ]}
        ></Product>
      </div>
      <Product
        title="Typist Documents"
        shortTitle="documents"
        description="Typist Documents is a unique simulated learning experience for document production courses. With over 75+ business documents that provide instant and automated feedback, students experience real-world scenarios that lead to employable skills and stronger achievement."
        items={[
          {
            title: 'Instant, Automated Feedback',
            bodyText:
              'Assignments are instantly graded in 6 seconds or less, and students can submit their assignments until perfect. More opportunities for practice lead to stronger results.',
            image: <ImageAutomatedFeedback></ImageAutomatedFeedback>,
          },
          {
            title: 'Workplace Experience',
            bodyText:
              'Students are hired at Key Performance Consulting, where they work through realistic scenarios within health care, law, technology, consumer packaged goods, the not-for-profit sector and more.',
            image: <ImageWorkplaceExperience></ImageWorkplaceExperience>,
          },
          {
            title: 'Formative Assessments',
            bodyText:
              'These bite-size assignments allow students to master the individual components of documents, and encourage the right behaviours on the path to full document mastery.',
            image: <ImageFormativeAssessments></ImageFormativeAssessments>,
          },
          {
            title: 'Free, Included Style Guide',
            bodyText:
              '100+ page style guide that guides students on the correct structure, formatting and writing style used by real businesses.',
            image: <ImageStyleGuide></ImageStyleGuide>,
          },
          {
            title: '75+ Documents',
            bodyText:
              'Emails, business letters, memorandums, business personal letters, multi-page business letters, reports and more.',
            image: <Image75Documents></Image75Documents>,
          },
        ]}
        reverseOrder={true}
      ></Product>
    </section>
  )
}

export default OurProducts
