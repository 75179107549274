import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

const Hero = () => {
  return (
    <div className="container my-typist56 mt-md-typist64 mb-md-typist96 mt-xl-typist96 mb-xl-typist160">
      <section className="row" aria-labelledby="heroHeading">
        <div className="col-12 col-xl-6 d-flex flex-column justify-content-center align-items-center text-center text-xl-start">
          <div
            className="py-0 mb-typist48 mb-xl-0"
            style={{ maxWidth: 1320 / 2 }}
          >
            <h1 className="fs-2 fw-bold lh-typist64" id="heroHeading">
              Thoughtfully designed technology for
              <span className="text-typist-pink d-block">
                better college courses.
              </span>
            </h1>
            <p className="lead lh-typist40 my-typist32 my-md-typist40">
              Top educators use Typist to empower their students with
              keyboarding and document production skills.
            </p>
            <Link to="/contact" className="btn btn-primary px-4 py-3 fw-bold">
              Learn more
            </Link>
          </div>
        </div>
        <div className="col-12 col-xl-6 d-flex justify-content-center align-items-center">
          <div className="ps-0 ps-xl-typist48">
            <StaticImage
              src="../images/hero_desktop@2x.png"
              placeholder="none"
              alt=""
              // The Image should be the min of:
              // - half the max container size in largest container size (xxl: 1320px)
              // - full container size in container size when image is full width (lg: 960px)
              // - a max width based on design in Figma
              width={560}
            ></StaticImage>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Hero
