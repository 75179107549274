import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import * as baristaHeroStyles from "./baristaHero.module.scss"
import classnames from "classnames"

// This image has infinite horizontal lines on either side
// See README.md for explanation of strategy
const BaristaHero = () => {
  return (
    <div className="fluid-container mt-tpyist56 mt-md-typist96 mt-xl-typist240 position-relative">
      {/* Mobile view only */}
      <div className="d-flex d-md-none justify-content-center">
        <div
          className={classnames(
            baristaHeroStyles.baristaHeroLeftMobile,
            "flex-grow-1"
          )}
        ></div>
        <div className="d-flex typist-responsive-container">
          <StaticImage
            src="../images/barista_hero_center_mobile@2x.png"
            alt=""
            placeholder="none"
            width={540}
          ></StaticImage>
        </div>
        <div
          className={classnames(
            baristaHeroStyles.baristaHeroRightMobile,
            "flex-grow-1"
          )}
        ></div>
      </div>
      {/* Tablet view only */}
      <div className="d-none d-md-flex d-xl-none justify-content-center">
        <div
          className={classnames(
            baristaHeroStyles.baristaHeroLeftTablet,
            "flex-grow-1"
          )}
        ></div>
        <div className="d-flex typist-responsive-container">
          <StaticImage
            src="../images/barista_hero_center_tablet@2x.png"
            alt=""
            placeholder="none"
            width={960}
          ></StaticImage>
        </div>
        <div
          className={classnames(
            baristaHeroStyles.baristaHeroRightTablet,
            "flex-grow-1"
          )}
        ></div>
      </div>
      {/* Desktop view only */}
      <div className="d-none d-xl-flex justify-content-center">
        <div
          className={classnames(
            baristaHeroStyles.baristaHeroLeftDesktop,
            "flex-grow-1"
          )}
        ></div>
        <div className="d-flex typist-responsive-container">
          <StaticImage
            src="../images/barista_hero_center_desktop@2x.png"
            alt=""
            placeholder="none"
            width={1320}
          ></StaticImage>
        </div>
        <div
          className={classnames(
            baristaHeroStyles.baristaHeroRightDesktop,
            "flex-grow-1"
          )}
        ></div>
      </div>
    </div>
  )
}

export default BaristaHero
