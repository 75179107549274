import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import * as deskHeroStyles from "./deskHero.module.scss"
import classnames from "classnames"

// This image has infinite horizontal lines on either side
// See README.md for explanation of strategy
const DeskHero = () => {
  return (
    <div className="fluid-container position-relative">
      {/* Mobile view only */}
      <div className="d-flex d-md-none justify-content-center">
        <div
          className={classnames(
            deskHeroStyles.deskHeroLeftMobile,
            "flex-grow-1"
          )}
        ></div>
        <div className="d-flex typist-responsive-container">
          <StaticImage
            src="../images/desk_hero_center_mobile@2x.png"
            alt=""
            placeholder="none"
            width={540}
          ></StaticImage>
        </div>
        <div
          className={classnames(
            deskHeroStyles.deskHeroRightMobile,
            "flex-grow-1"
          )}
        ></div>
      </div>
      {/* Tablet view only */}
      <div className="d-none d-md-flex d-xl-none justify-content-center">
        <div
          className={classnames(
            deskHeroStyles.deskHeroLeftTablet,
            "flex-grow-1"
          )}
        ></div>
        <div className="d-flex typist-responsive-container">
          <StaticImage
            src="../images/desk_hero_center_tablet@2x.png"
            alt=""
            placeholder="none"
            width={960}
          ></StaticImage>
        </div>
        <div
          className={classnames(
            deskHeroStyles.deskHeroRightTablet,
            "flex-grow-1"
          )}
        ></div>
      </div>
      {/* Desktop view only */}
      <div className="d-none d-xl-flex justify-content-center">
        <div
          className={classnames(
            deskHeroStyles.deskHeroLeftDesktop,
            "flex-grow-1"
          )}
        ></div>
        <div className="d-flex typist-responsive-container">
          <StaticImage
            src="../images/desk_hero_center_desktop@2x.png"
            alt=""
            placeholder="none"
            width={1320}
          ></StaticImage>
        </div>
        <div
          className={classnames(
            deskHeroStyles.deskHeroRightDesktop,
            "flex-grow-1"
          )}
        ></div>
      </div>
    </div>
  )
}

export default DeskHero
