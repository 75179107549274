import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import * as statsSectionStyles from "./statsSection.module.scss"
import classnames from "classnames"

const StatsText = () => (
  // Position from top should be a percent of image container height, so that text is always position in same relative spot
  <div
    className="position-absolute w-100 h-100 left-0 d-flex justify-content-center"
    style={{ top: "20%" }}
  >
    {/* Row width should be relative to text, so it doesnt break into a new line unexpectedly */}
    <div className="row" style={{ width: "50rem" }}>
      <div className="col d-flex flex-column text-center">
        <span className="fs-1 fw-bold text-primary lh-typist72 mb-typist8">
          50+
        </span>
        <span className="fs-4 lh-typist32">
          Academic
          <br />
          Programs
        </span>
      </div>
      <div className="col d-flex flex-column text-center">
        <span className="fs-1 fw-bold text-primary lh-typist72 mb-typist8">
          5,000+
        </span>
        <span className="fs-4 lh-typist32">
          Active
          <br />
          Students
        </span>
      </div>
      <div className="col d-flex flex-column text-center">
        <span className="fs-1 fw-bold text-primary lh-typist72 mb-typist8">
          30+
        </span>
        <span className="fs-4 lh-typist32">
          Participating
          <br />
          Colleges
        </span>
      </div>
    </div>
  </div>
)

// TODO: refactor this mobile functionality into the StatsText component
// Currently the mobile version of stats is using a different font size, so
// keeping it as a separate component was faster during prototyping
const StatsTextMobile = () => (
  // Position from top should be a percent of image container height, so that text is always position in same relative spot
  <div
    className="position-absolute w-100 h-100 left-0 d-flex flex-column mt-typist16"
    style={{ top: "0%" }}
  >
    <div className="d-flex flex-column text-center my-4">
      <span className="fs-1 fw-bold lh-typist72" style={{ color: "#0066FF" }}>
        50+
      </span>
      <span className="fs-4 lh-typist32">
        Academic
        <br />
        Programs
      </span>
    </div>
    <div className="d-flex flex-column text-center my-4">
      <span className="fs-1 fw-bold lh-typist72" style={{ color: "#0066FF" }}>
        5,000+
      </span>
      <span className="fs-4 lh-typist32">
        Active
        <br />
        Students
      </span>
    </div>
    <div className="d-flex flex-column text-center my-4">
      <span className="fs-1 fw-bold lh-typist72" style={{ color: "#0066FF" }}>
        30+
      </span>
      <span className="fs-4 lh-typist32">
        Participating
        <br />
        Colleges
      </span>
    </div>
  </div>
)

// This image has infinite horizontal lines on either side
// See README.md for explanation of strategy
// In this particular image, 3 different podium images are used (with 3 different sets of flanking images)
// for mobile, tablet and desktop views
const StatsSection = () => {
  return (
    <div className="fluid-container mt-typist56 mb-typist56 mt-md-typist96 mb-md-tpyist96 mt-xl-typist160 mb-xl-typist240 position-relative">
      {/* Mobile view only (< 1024px image) */}
      <div className="d-flex d-md-none justify-content-center">
        <div
          className={classnames(
            statsSectionStyles.podiumLeftMobile,
            "flex-grow-1"
          )}
        ></div>
        <div className="d-flex typist-responsive-container position-relative">
          <StaticImage
            src="../images/podium_center_mobile@2x.png"
            placeholder="none"
            alt=""
            width={540}
          ></StaticImage>
          <StatsTextMobile></StatsTextMobile>
        </div>
        <div
          className={classnames(
            statsSectionStyles.podiumRightMobile,
            "flex-grow-1"
          )}
        ></div>
      </div>
      {/* Tablet view only */}
      <div className="d-none d-md-flex d-xl-none justify-content-center">
        <div
          className={classnames(
            statsSectionStyles.podiumLeftTablet,
            "flex-grow-1"
          )}
        ></div>
        <div className="d-flex typist-responsive-container position-relative">
          <StaticImage
            src="../images/podium_center_tablet@2x.png"
            placeholder="none"
            alt=""
            width={960}
          ></StaticImage>
          <StatsText></StatsText>
        </div>
        <div
          className={classnames(
            statsSectionStyles.podiumRightTablet,
            "flex-grow-1"
          )}
        ></div>
      </div>
      {/* Desktop view only */}
      <div className="d-none d-xl-flex justify-content-center">
        <div
          className={classnames(
            statsSectionStyles.podiumLeftDesktop,
            "flex-grow-1"
          )}
        ></div>
        <div className="d-flex typist-responsive-container position-relative">
          <StaticImage
            src="../images/podium_center_desktop@2x.png"
            placeholder="none"
            alt=""
            width={1320}
          ></StaticImage>
          <StatsText></StatsText>
        </div>
        <div
          className={classnames(
            statsSectionStyles.podiumRightDesktop,
            "flex-grow-1"
          )}
        ></div>
      </div>
    </div>
  )
}

export default StatsSection
