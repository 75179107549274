import React from 'react'
import { Link } from 'gatsby'

const LetUsHelp = () => {
  return (
    <div className="container py-typist40 py-md-typist96 py-xl-typist160">
      <section className="d-flex justify-content-center align-items-center flex-column">
        <h2 className="text-center mb-typist24 mb-md-typist32 fw-bold">
          Let us help you <span className="text-typist-pink">build</span> your
          curriculum.
        </h2>
        <Link
          to="/contact"
          className="btn btn-primary btn-lg text-white px-5 py-3 fw-bold"
        >
          Let's talk
        </Link>
      </section>
    </div>
  )
}

export default LetUsHelp
