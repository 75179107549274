import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import * as bikeHeroStyles from "./bikeHero.module.scss"
import classnames from "classnames"

// This image has infinite horizontal lines on either side
// See README.md for explanation of strategy
const BikeHero = () => {
  return (
    <div className="fluid-container my-typist56 my-md-typist96 my-xl-typist240 position-relative">
      {/* Mobile view only */}
      <div className="d-flex d-md-none justify-content-center">
        <div
          className={classnames(
            bikeHeroStyles.bikeHeroLeftMobile,
            "flex-grow-1"
          )}
        ></div>
        <div className="d-flex typist-responsive-container">
          <StaticImage
            src="../images/bike_hero_center_mobile@2x.png"
            alt=""
            placeholder="none"
            width={540}
          ></StaticImage>
        </div>
        <div
          className={classnames(
            bikeHeroStyles.bikeHeroRightMobile,
            "flex-grow-1"
          )}
        ></div>
      </div>
      {/* Tablet view only */}
      <div className="d-none d-md-flex d-xl-none justify-content-center">
        <div
          className={classnames(
            bikeHeroStyles.bikeHeroLeftTablet,
            "flex-grow-1"
          )}
        ></div>
        <div className="d-flex typist-responsive-container">
          <StaticImage
            src="../images/bike_hero_center_tablet@2x.png"
            alt=""
            placeholder="none"
            width={960}
          ></StaticImage>
        </div>
        <div
          className={classnames(
            bikeHeroStyles.bikeHeroRightTablet,
            "flex-grow-1"
          )}
        ></div>
      </div>
      {/* Desktop view only */}
      <div className="d-none d-xl-flex justify-content-center">
        <div
          className={classnames(
            bikeHeroStyles.bikeHeroLeftDesktop,
            "flex-grow-1"
          )}
        ></div>
        <div className="d-flex typist-responsive-container">
          <StaticImage
            src="../images/bike_hero_center_desktop@2x.png"
            alt=""
            placeholder="none"
            width={1320}
          ></StaticImage>
        </div>
        <div
          className={classnames(
            bikeHeroStyles.bikeHeroRightDesktop,
            "flex-grow-1"
          )}
        ></div>
      </div>
    </div>
  )
}

export default BikeHero
